'use client'

import { ReactNode } from 'react'
import { Variants } from 'framer-motion'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

import { Listings } from '@/data/getListings'
import { FavouriteIcon } from '../Favourites/FavouriteIcon'
import { BathroomIcon } from '../icons/BathroomIcon'
import { BedIcon } from '../icons/BedIcon'
import { GarageIcon } from '../icons/GarageIcon'
import { PreviewImage } from '../PreviewImage'

type ListingPreviewProps = {
  className?: string
  listing: Listings[0]
  priority?: boolean
  imageChildren?: ReactNode | undefined
}

export const blur = { none: 'blur(0px)', small: 'blur(10px)' }

export const listingVariants: Variants = {
  in: { opacity: 1, scale: 1.0, filter: blur.none },
  out: { opacity: 0, scale: 0.75, filter: blur.small },
}

export const ListingPreview = function ListingPreview({
  className,
  listing,
  priority,
  imageChildren,
}: ListingPreviewProps) {
  const { id, address, price_advertise_as, image, attributes } = listing
  const area = Number(attributes.landarea || attributes.buildarea)
  const price = Number(price_advertise_as)
    ? Number(price_advertise_as).toLocaleString('en-AU', {
        style: 'currency',
        currency: 'AUD',
        maximumFractionDigits: 0,
      })
    : price_advertise_as

  const openHomes = listing.events
    .filter((event) => event.event_type === 'open_home')
    .map((event) => event.event_datetime_start)

  return (
    <Link href={`/buy/listing/${id}`} title={`${address.formats.street_name_number}, ${address.suburb_or_town}`}>
      <div
        data-property-id={id}
        data-open-homes={openHomes.length ? openHomes : undefined}
        className={twMerge('group flex flex-col items-center text-center transition duration-300', className)}
      >
        <PreviewImage
          url={`https:${image.thumbs._800x600}`}
          priority={priority}
          className="transition-transform duration-150 group-hover:drop-shadow-xl md:group-hover:scale-[1.03]"
          heart={<FavouriteIcon id={id} />}
        >
          {imageChildren}
        </PreviewImage>
        <div data-cy="street-address" className="mt-5 font-display text-base uppercase">
          {address.formats.street_name_number}
        </div>
        <div data-cy="suburb" className="mt-0.5 text-2xs font-normal uppercase tracking-widest">
          {address.suburb_or_town}
        </div>
        <div className="mt-5 flex gap-x-4 text-xs font-normal">
          {attributes.bedrooms?.length ? (
            <div
              data-cy="bedrooms"
              className="flex h-4 items-center justify-between gap-x-1 before:flex-1 before:shrink-0 before:text-xl"
            >
              <BedIcon className="mr-1.5" />
              {attributes.bedrooms}
            </div>
          ) : null}
          {attributes.bathrooms?.length ? (
            <div
              data-cy="bathrooms"
              className="flex h-4 items-center justify-between gap-x-1 before:flex-1 before:shrink-0 before:text-xl"
            >
              <BathroomIcon className="mr-1.5" />
              {attributes.bathrooms}
            </div>
          ) : null}
          {attributes.total_car_accom?.length ? (
            <div
              data-cy="cars"
              className="flex h-4 items-center justify-between gap-x-1 before:flex-1 before:shrink-0 before:text-xl"
            >
              <GarageIcon className="mr-1.5" />
              {attributes.total_car_accom}
            </div>
          ) : null}
          {area ? (
            <div
              data-cy="area"
              className="flex h-4 items-center justify-between gap-x-1 whitespace-nowrap before:flex-1 before:shrink-0 before:text-2xl"
            >
              {area.toLocaleString('en-AU', { maximumFractionDigits: 0 })}m²
            </div>
          ) : null}
        </div>
        <div data-cy="price" className="mt-5 font-body text-xs uppercase tracking-wider">
          {price}
        </div>
      </div>
    </Link>
  )
}
